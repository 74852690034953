// -----------------------------------------------------------------------------
// This file contains all styles related to the faq component.
// -----------------------------------------------------------------------------

.faq {
  details {
    width: 100%;
    margin-bottom: 0.125rem;
    background: $white;
    border-radius: $border-radius;
    overflow: hidden;
    
    @include media-breakpoint-up(md) {
      margin-bottom: 0.675rem;
    }

    summary {
      padding: 1rem 2.5rem 1rem 1.25rem;
      line-height: 1.25em;
      position: relative;

      @include media-breakpoint-up(md) {
        padding: 1.25rem 3rem 1.25rem 2rem;
      }

      &::marker {
        content: '';
      }

      &::before {
        content: '';
        width: 1rem;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 1rem;
        background: no-repeat 50% 50%;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.416 8.121'%3e%3cpath style='line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:%23000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-opacity:1' d='M1.416 0 .002 1.414l6.707 6.707 6.707-6.707L12.002 0 6.709 5.293Z' fill='%2307E34F'/%3e%3c/svg%3e");
        background-size: 100% auto;
        transition: .3s;
      }
    }

    &[open] {
      summary {
        &::before {
          transform: rotate(180deg);
        }
      }
    }

    .content {
      padding: 0 1.25rem 1.25rem 1.25rem;

      @include media-breakpoint-up(md) {
        padding: 0 2rem 1.25rem 2rem;
      }
    }
  }
}