// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.container {
  margin-left: auto; /* 2 */
  margin-right: auto; /* 2 */
  padding-left: 1rem; /* 3 */
  padding-right: 1rem; /* 3 */
  width: 100%; /* 1 */

  @include media-breakpoint-up(xxl) {
    max-width: $max-width;
  }

  &__narrow {
    @include media-breakpoint-up(lg) {
      max-width: 52rem;
    }
  }

  &-xs {
    @include media-breakpoint-down(sm) {
      max-width: 25rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

main {
  width: 100%;

  > section {
    position: relative;
  }
}

.homepage {
  main {
    overflow: hidden;
  }
}

aside {
  position: relative;
}

.img {
  &-fluid {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

hr {
  width: 100%;
  border: 0;
  border-top: 1px solid $border-color;
  margin: 1.5rem 0;
}

.text {
  &-color-default {
    color: $text-color;
  }

  &-black {
    color: $black !important;
  }

  &-white {
    color: $white !important;
  }

  &-xsmall {
    font-size: .375em;
  }

  &-small {
    font-size: .625em;
    line-height: 1.2em;
  }

  &-medium {
    font-size: .875em;
  }

  &-medium-large {
    font-size: 1.125em;
  }

  &-large {
    font-size: 1.25em;
  }

  &-italic {
    font-style: italic;
  }

  &-right {
    text-align: right;
  }

  &-muted {
    color: $text-color-light !important;
  }

  &-medium-bold {
    font-weight: 500;
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-normal {
    text-transform: none;
  }

  &-plain {
    font-family: $font-text;
  }
}

.break-word {
  word-break: break-word;
}

.photo-placeholder {
  @include flex($flexContent:center);
  width: 100%;
  height: 100%;
  background: $gray-100;

  .photo-placeholder-img {
    width: 50%;
    height: auto;
    object-fit: unset;
  }
}

.sticky {
  &-track {
    position: relative;
  }

  &-item {
    margin: 0 auto 2rem;
    position: sticky;
    top: 6rem;
    left: 0;
    right: 0;
    z-index: 10;
  }
}

.list {
  padding-left: 1.75rem;

  li {
    padding: .25rem 1rem .25rem 0;

    &::marker {
      color: $theme-color-1;
    }
  }

  &-col-2 {
    @include media-breakpoint-up(sm) {
      width: fit-content;
      columns: 2;
    }

    @include media-breakpoint-up(lg) {
      width: fit-content;
      columns: 4;
    }

    li {
      break-inside: avoid-column;
      @include media-breakpoint-up(sm) {
        padding-right: 3rem;
      }
    }
  }
}

.reset-list {
  @include reset-list;
}

.border-default {
  border-radius: 1rem 0 1rem 0;
}

.bg {
  &__gray {
    background: $gray-100;
    &-gradient{
      background: linear-gradient(180deg, $gray-100, $gray-100 85%, $gray-200);
    }
  }

  &__white {
    background: $white;

    &-totop {
      background: $white;

      @include media-breakpoint-up(lg) {
        background: linear-gradient(180deg, transparent 5rem, rgba($white,.3) 10rem, rgba($white,.6) 15rem, rgba($white,.9) 20rem, $white 25rem)
      }
    }
  }

  &__black {
    background: $black;
    color: $white;

    input {
      background: $black;
      color: $white;
      border-color: $white;

      &::placeholder {
        color: $white;
      }
    }
  }

  &__blue {
    background: hsl(124 , 100% , 86%);

  }

  &__special-1 {
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      width: calc(100% + 9rem);
      height: 100%;
      position: absolute;
      top: 0;
      left: -4.5rem;
      border-radius: 3rem 0 3rem 0;
      background: $theme-color-1;
      transform: skewX(-10deg);

      @include media-breakpoint-up(sm) {
        width: 100%;
        left: 0;
      }

      @include media-breakpoint-up(lg) {
        transform: skewX(-20deg);
      }
    }

    mark {
      color: $white;
    }

    * {
      position: relative;
    }
  }

  &__special-2 {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4432.33 4572.61'%3e%3cg fill='%23fff' fill-opacity='.03'%3e%3cpath data-name='Path 1543' d='M397.426 34.397A384.743 384.743 0 0 0 65.733 277.333L-22.741 520.27h486.268L640.532 33.945z'/%3e%3cpath data-name='Path 1544' d='M595.843 1493.54c268.586 0 565.533-217.738 663.33-486.267l176.947-486.325H463.584L109.518 1493.54z'/%3e%3cpath data-name='Path 1637' d='M3134.472 2266.36a384.687 384.687 0 0 1 331.636 242.937l88.53 242.937H3068.37l-177.004-486.268z'/%3e%3cpath data-name='Path 1638' d='M2936.055 3725.222c-268.586 0-565.534-217.739-663.33-486.268l-176.948-486.325h972.593l354.01 972.593z'/%3e%3c/g%3e%3c/svg%3e"), linear-gradient(0deg, #062839 0%, $black 100%);
    background-size: 4rem auto;
  }
}


.fade {
  transition: opacity .15s linear;

  &:not(.show) {
    opacity: 0;
  }
}