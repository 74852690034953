// -----------------------------------------------------------------------------
// This file contains font icons classes.
// -----------------------------------------------------------------------------

footer
{
      &  [class*='icon-'] {
        color: $theme-color-4;
      }
}

[class*='icon-'] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;

  &:before {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: 'carfin';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
  }
}

.icon-cancel:before,
.icon-warning:before,
.icon-error:before{content:'\0041';}
.icon-car:before,
.icon-osobowe:before{content:'\0042';}
.icon-check:before,
.icon-success::before{content:'\0043';}
.icon-chevron-down:before{content:'\0044';}
.icon-collapse:before{content:'\0045';}
.icon-drive:before{content:'\0046';}
.icon-email:before{content:'\0047';}
.icon-facebook:before{content:'\0048';}
.icon-filter:before{content:'\0049';}
.icon-fuel:before{content:'\004a';}
.icon-hamburger:before{content:'\004b';}
.icon-horsepower:before{content:'\004c';}
.icon-instagram:before{content:'\004d';}
.icon-linkedin:before{content:'\004e';}
.icon-motorcycle,
.icon-motocykle:before{content:'\004f';}
.icon-phone:before{content:'\0050';}
.icon-search:before{content:'\0051';}
.icon-star-full:before{content:'\0052';}
.icon-touch:before{content:'\0053';}
.icon-transmission:before{content:'\0054';}
.icon-truck:before,
.icon-dostawcze:before {content:'\0055';}
.icon-user:before{content:'\0056';}
.icon-youtube:before{content:'\0057';}
.icon-chevron-left:before{
  content:'\0044';
  transform: rotate(90deg);
}