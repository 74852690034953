// <--------------------------------------------------------------------->
// <------------------------------ Error 404 ------------------------------>
// <--------------------------------------------------------------------->

$error-border-radius: 20px;

.container {
  &--blue {
    background-color: $theme-color-4;

    .btn {
      &--cta {
        margin-top: auto;
        padding: 15px;
      }
    }

    & .btn-primary {
      width: 100%;
      justify-content: center;
    }
  }
}

.text__error {
  &--desc {
    font-weight: bold;
    font-size: 1.25rem;
  }

  &--cta {
    font-weight: bold;
    color: gray;
    font-size: 1rem;
  }
}

.cta {
  display: flex;
  justify-content: center;
}

.block {

  &--left,
  &--right {
    height: 100%;
    min-height: 600px;
    display: flex;
    justify-content: space-between;
    background-color: $white;
  }

  &--right {
    background-image: url('/public/images/error_404.png');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    &-500 {
      background-image: url('/public/images/error_500.png');
      background-size: cover;
      background-position: 90% 75%;
      background-repeat: no-repeat;
    }

  }

  &--left {
    display: flex;
    flex-direction: column;
  }
}

.error {
  &__logo {
    max-width: 50%;
  }

  &__card {
    border-radius: $border-radius;
    overflow: hidden;
  }
}

.mt-md-10 {
  margin-top: 8rem;
}


@media only screen and (max-width: 1050px) {

  .block {

    &--left,
    &--right,
    &--right-500 {
      min-height: 550px;
    }
  }

  .mt-md-10 {
    margin-top: 1rem;
  }

  div.block--right-500 {
    background-position: 75% 50%;
  }

  p.text__error {
    &--desc {
      font-size: 1.15rem;
    }

    &--cta {
      margin-top: auto;
      font-size: .75rem;
      width: 100%;
    }
  }
}
