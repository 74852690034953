// -----------------------------------------------------------------------------
// This file contains all styles related to the modal component.
// -----------------------------------------------------------------------------

.modal {
  &-dialog {

    @include media-breakpoint-up(sm) {
      max-width: calc(100% - 2rem);
    }

    @include media-breakpoint-up(xl) {
      max-width: 75rem;
    }

    .btn-close {
      justify-content: center;
      width: 2.5rem;
      height: 2.5rem;
      padding: 0;
      position: absolute;
      top: 1rem;
      right: 1rem;
      z-index: 1;
      background: rgba($white,.7);
      color: rgba($text-color, .6);
      border: 0;
      border-radius: 50%;
      font-size: 2.5rem;

      &::before {
        content: '×';
      }

      @include on-event {
        background: $theme-color-1;
        color: $white;
      }
    }
  }

  &-header {
    &--image {
      display: block;
      width: 100%;
      height: auto;
      aspect-ratio: 3/1;
      object-fit: cover;

      @include media-breakpoint-up(md) {
        aspect-ratio: 2.5/1;
        border-radius: calc($border-radius * 2);
      }
    }
  }

  &-content {
    border-radius: calc($border-radius * 2);
    overflow: hidden;

    &--scrollable {
      max-height: 40vh;
      overflow: auto;
    }
  }

  &-body {
    padding: 0;

    .h1 {
      font-size: 1.5rem;
      font-weight: 700;
      text-transform: none;

      @include media-breakpoint-up(xl) {
        font-size: 2.625rem;
      }
    }
  }

  &-backdrop {
    background: rgba($black,.96);

    @supports (backdrop-filter: blur(1rem)) {
      background: rgba($black,.8);
      backdrop-filter: blur(1rem);
    }

    &.show {
      opacity: 1;
    }
  }

  &-logo {
    width: 8rem;
    height: 1.625rem;
  }

  &-thank-you {
    &--image {
      display: block;
      width: 100%;
      height: auto;
      aspect-ratio: 3/1;
      object-fit: cover;

      @include media-breakpoint-up(md) {
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--data {
      color: $white;
      background: linear-gradient(transparent 0, rgba($black, 0.7) 30%);
      padding-top: 5rem;
    }
  }
}