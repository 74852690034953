// -----------------------------------------------------------------------------
// This file contains all site-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Kitty Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

@mixin reset-list {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

@mixin transition {
  transition: .3s;
}

@mixin flex($flexDisplay: flex, $flexContent: flex-start, $flexItems: center, $flexWrap: nowrap) {
  display: $flexDisplay;
  justify-content: $flexContent;
  align-items: $flexItems;
  flex-wrap: $flexWrap;
}