// -----------------------------------------------------------------------------
// This file contains all styles related to the benefits component.
// -----------------------------------------------------------------------------

.benefits {
  &--img {
    &-lg {
      position: absolute;
      left: 60%;
      top: -3rem;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-sm {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 2rem auto;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}