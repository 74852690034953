// -----------------------------------------------------------------------------
// This file contains all styles related to the product component.
// -----------------------------------------------------------------------------

.product {
  &-header {
    h1 {
      @include media-breakpoint-down(sm) {
        font-size: 2rem;
      font-weight: 700;
      }
    }

    .h3 {
      @include media-breakpoint-down(sm) {
        font-weight: 700;
        font-size: 1.125rem;
      }
    }

    &--count {
      display: inline-block;
      vertical-align: text-bottom;
      min-width: 2rem;
      margin-left: .25rem;
      border: 1px solid darken($border-color-dark,10%);
      padding: .25rem .375rem;
      border-radius: $border-radius;
      text-align: center;
      font-family: $font-text;
      font-size: .625em;
      font-weight: 500;
      line-height: 1.2em;
    }
  }

  &-gallery {
    .row {
      row-gap: 2rem;
    }

    .col-6 {
      aspect-ratio: 16/9;
    }

    img {
      display: block;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 16/9;
      border-radius: $border-radius;
    }
  }

  &-price {
    height: 2rem;
    font-size: 2rem;
    line-height: 1em;

    @include media-breakpoint-up(xl) {
      height: 2.625rem;
      font-size: 2.625rem;
    }

    &-md {
      font-size: 1.875rem;

      @include media-breakpoint-up(xl) {
        font-size: 2.25rem;
      }
    }

    strong {
      font-weight: 900;
      color: $theme-color-1;
    }

    span {
      font-weight: 400;
    }
  }

  &-filters {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(lg) {
      overflow: hidden;
      max-height: 0;
      transition: .3s;
    }

    &.is-visible {
      max-height: 50rem;
    }

    form {
      width: 100%;
    }

    &--price {
      width: 16rem;

      @include media-breakpoint-up(xl) {
        width: 19rem;
      }
    }

    &-btn {
      height: 3rem;
      position: absolute;
      right: 1rem;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
}

.text-white {
  .product-header--count {
    border-color: $white;
  }
}