// -----------------------------------------------------------------------------
// This file contains all styles related to the order steps component.
// -----------------------------------------------------------------------------

.order-steps {
  @include flex($flexWrap:wrap,$flexContent:space-between,$flexItems:flex-start);
  @include reset-list;
  position: relative;

  &::before {
    @include media-breakpoint-up(lg) {
      content: '';
      width: 84%;
      height: 1.375rem;
      position: absolute;
      top: 2.875rem;
      left: 8%;
      right: 0;
      background: no-repeat 0 50%;
      background-size: cover;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1051.876 18.152'%3e%3cpath data-name='Path 2958' d='M.047 10.141s178.8 16.864 343.376 0 289.229-8.127 357.6 0 264.135 10.769 350.792 0' fill='none' stroke='%23000' stroke-dasharray='5'/%3e%3c/svg%3e");
    }
  }

  &--item {
    flex-basis: 100%;
    padding: .5rem 0;
    text-align: center;
    position: relative;

    @include media-breakpoint-up(sm) {
      flex-basis: 50%;
      padding: 2rem 0;
    }

    @include media-breakpoint-up(lg) {
      flex-basis: unset;
    }

    &::before {
      content: '';
      width: 12.5rem;
      max-width: 65%;
      height: 102%;
      position: absolute;
      top: 2.125rem;
      left: 45%;
      border-radius: 0 50% 50% 0;
      border: dashed $black;
      border-width: 2px 2px 2px 0;

      @include media-breakpoint-up(sm) {
        width: 100%;
        max-width: unset;
        height: 4rem;
        top: 3.5rem;
        left: 50%;
        border-radius: 0 4rem 0 0;
        border-width: 2px 2px 0 0;
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &:nth-of-type(2) {
      @include media-breakpoint-up(sm) {
        margin-top: 4rem;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
      
      &::before {
        content: '';
        width: 12.5rem;
        max-width: 65%;
        left: unset;
        right: 45%;
        border-radius: 50% 0 0 50%;
        border-width: 2px 0 2px 2px;

        @include media-breakpoint-up(sm) {
          width: 50%;
          max-width: unset;
          top: unset;
          bottom: -2rem;
          right: 50%;
          border-radius: 0 0 4rem 0;
          border-width: 0px 2px 2px 0;
        }
      }
    }

    &:nth-of-type(3) {
      @include media-breakpoint-up(sm) {
        margin-top: 4rem;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }

      &::before {
        @include media-breakpoint-up(sm) {
          width: 50%;
          height: 6.5rem;
          max-width: unset;
          top: unset;
          bottom: -4.875rem;
          border-radius: 0 0 0 4rem;
          border-width: 0 0 2px 2px;
        }
      }

      &::after {
        @include media-breakpoint-up(sm) {
          content: '';
          width: 50%;
          height: 4rem;
          position: absolute;
          top: -2.125rem;
          left: 50%;
          border: dashed $black;
          border-radius: 4rem 0 0 0;
          border-width: 2px 0 0 2px;
        }

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }

    }

    &:last-of-type {
      @include media-breakpoint-up(sm) {
        margin-top: 8rem;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }

      &::before {
        display: none;

        @include media-breakpoint-up(sm) {
          display: block;
          width: 50%;
          max-width: unset;
          top: unset;
          bottom: -2.125rem;
          left: unset;
          right: 50%;
          border-radius: 0 0 4rem 0;
          border-width: 0px 2px 2px 0;
        }

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }

    &-step {
      @include flex($flexContent:center);
      width: 3.375rem;
      height: 3.375rem;
      margin: auto;
      background: $theme-color-1;
      border: .5rem solid $theme-color-1;
      border-radius: 50%;
      font-family: $font-header;
      font-size: 1.5rem;
      position: relative;
      
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='white' stroke-width='4' stroke-dasharray='2%2c8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      }
    }

    &-img {
      display: block;
      width: 4.75rem;
      height: 4.75rem;
      margin: .5rem auto;
    }

    &-text {
      display: block;
      font-size: .875rem;
      font-weight: 500;

      @include media-breakpoint-up(xl) {
        font-size: 1.125rem;
      }
    }
  }
}