// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site.
// -----------------------------------------------------------------------------
.cookies {
  position: fixed;
  margin: 0 auto;
  bottom: 5rem;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;

  &__content {
    border-radius: $border-radius;
    box-shadow: 0 0 3rem rgba($black, .4);

    @include media-breakpoint-up(md) {
      border-radius: ($border-radius * 2);
    }

    h3 {
      text-transform: none;
    }
  }

  &__img {
    width: clamp(6rem, 30vw, 10rem);
    height: clamp(6rem, 30vw, 10rem);
    object-fit: contain;
  }

  &.hidden {
    display: none;
  }
}

.footer {
  font-family: $font-header;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5rem;
  position: relative;

  &--head {
    color: $theme-color-4;
    font-size: 1rem;
  }

  &--nav {
    @include reset-list;

    &-item {

      &-url,
      &-url.btn-link {
        color: $text-color;
        padding: 1rem 0;
        line-height: 2.5rem;
        white-space: nowrap;

        @include media-breakpoint-up(sm) {
          padding: 0;
          line-height: 1;
        }

        &::before {
          color: $theme-color-4;
          transition: .1s;
        }

        @include on-event {
          color: $theme-color-1;

          &::before {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  &--social {
    @include reset-list;

    &-item {
      padding: 0 0 1rem 0;

      &-url {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        border: 1px solid $theme-color-4;
        border-radius: 50%;
        font-size: 1rem;
        transition: .1s;

        &:hover {
          color: $theme-color-1;
          border-color: $theme-color-1;
          transform: scale(1.1);
        }
      }
    }
  }

  &--copy {
    font-size: 0.75rem;
    color: $text-color-light;

    span {
      display: inline-block;
      vertical-align: middle;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
}
