// -----------------------------------------------------------------------------
// This file contains all styles related to the testimonials component.
// -----------------------------------------------------------------------------

.testimonials {
  position: relative;
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;

  &--carousel {
    width: 220vw;
    margin-left: -60vw;
    overflow: visible;

    @include media-breakpoint-up(sm) {
      width: 180vw;
      margin-left: -40vw;
    }

    @include media-breakpoint-up(md) {
      width: 160vw;
      margin-left: -30vw;
    }

    @include media-breakpoint-up(xl) {
      width: 100%;
      margin: 0;
    }

    &::before,
    &::after {
      content: '';
      width: 2rem;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 10;
    }

    &::before {
      left: 0rem;
      background-image: linear-gradient(90deg, $gray-100 0%, transparent 100%);
    }

    &::after {
      right: 0;
      background-image: linear-gradient(-90deg, $gray-100 0%, transparent 100%);
    }
  }

  &--item {
    @include flex($flexItems:flex-start, $flexWrap:wrap);
    row-gap: 1rem;
    column-gap: 2rem;
    max-width: 17rem;
    margin: 0 auto;
    padding: 0 .5rem;
    position: relative;

    @include media-breakpoint-up(xsm) {
      max-width: 21rem;
      padding: 0 1rem;
    }

    @include media-breakpoint-up(sm) {
      padding: 0 1.5rem;
    }

    @include media-breakpoint-up(lg) {
      max-width: unset;
      padding: 0 3rem;
    }

    &-img {
      display: block;
      max-width: 9rem;
      height: 9rem;
      margin: 0 auto;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &-description {
      flex-basis: 100%;

      @include media-breakpoint-up(xl) {
        flex: 1;
        position: relative;
      }

      &::before {
        content: '';
        width: 3.5rem;
        height: 2rem;
        position: absolute;
        top: 0;
        left: 0;
        background: no-repeat 50% 50%;
        background-size: 100% auto;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg data-name='Group 318' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 54.097 30.131'%3e%3cpath data-name='Path 3040' d='m43.103 0 7.312 20.088h.027l3.656 10.044h-5.023a7.944 7.944 0 0 1-6.85-5.022l-1.828-5.022h-.027c-5.548 0-11.681-4.5-13.7-10.044L23.015 0Z' fill='%23e2e2e2'/%3e%3cpath data-name='Path 3041' d='M20.088 0 27.4 20.088h.027l3.656 10.044H26.06a7.944 7.944 0 0 1-6.85-5.022l-1.828-5.022h-.026c-5.548 0-11.681-4.5-13.7-10.044L0 0Z' fill='%23e2e2e2'/%3e%3c/svg%3e");

        @include media-breakpoint-up(xsm) {
          left: 1rem;
        }

        @include media-breakpoint-up(xl) {
          left: 0;
        }
      }

      p {
        font-style: italic;

        &:first-of-type {
          margin-bottom: .5rem;
        }
      }
    }

    &-stars {
      @include flex($flexContent:center);
      gap: 0.25rem;
      margin-bottom: 0.5rem;

      @include media-breakpoint-up(xl) {
        @include flex($flexContent:flex-end);
        margin-bottom: 1rem;
      }

      .icon-star-full {
        font-size: 1.5rem;
        color: $theme-color-2;
      }
    }

    &-author {
      text-align: right;

      @include media-breakpoint-up(xl) {
        text-align: left;
      }
    }
  }

  .carousel--pagination {
    bottom: unset;
    top: -2.5rem;

    > .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
      background: $gray-300;
    }
  }
}