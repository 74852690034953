// -----------------------------------------------------------------------------
// This file contains all styles related to the typography of the site.
// -----------------------------------------------------------------------------

body {
  color: $text-color;
  font: normal 1rem / 1.4 $font-text;
  letter-spacing: 0.025rem;
}

strong,
b {
  font-weight: 600;
}


h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0 0 1rem;
  line-height: 1.2em;
  font-family: $font-header;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.025rem;

  &:last-child {
    margin: 0;
  }

  mark {
    color: $theme-color-1;
    background-color: unset;
  }
}

* + {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin-top: 2rem;
  }
}

h1, 
.h1 {
  font-size: 2rem;
  line-height: 1.1em;
  font-weight: 700;

  @include media-breakpoint-up(lg) {
    font-size: 2.875rem;
    font-weight: 900;
  }

  @include media-breakpoint-up(xl) {
    font-size: 3.375rem;
  }

  strong {
    font-weight: 900;
  }
}

h2, 
.h2 {
  font-size: 1.75rem;

  @include media-breakpoint-up(xl) {
    font-size: 2.25rem;
  }

  strong {
    font-weight: 900;
  }
}

h3, 
.h3 {
  font-size: 1.3125rem;

  @include media-breakpoint-up(xl) {
    font-size: 1.5rem;
  }

  strong {
    font-weight: 900;
  }
}

h4, 
.h4 {
  font-size: 1.125rem;

  @include media-breakpoint-up(xl) {
    font-size: 1.25rem;
  }
}

h5, 
.h5 {
  font-size: 1rem;

  @include media-breakpoint-up(xl) {
    font-size: 1.125rem;
  }
}

h6, 
.h6 {
  font-size: .875rem;

  @include media-breakpoint-up(xl) {
    font-size: 1rem;
  }
}

p {
  margin: 0 0 1rem;

  &:last-child {
    margin: 0;
  }
}

.text-header {
  font-family: $font-header;
}
