// -----------------------------------------------------------------------------
// This file contains all styles related to the alert component.
// -----------------------------------------------------------------------------

.alert {
  width: 100%;
  padding: 1rem;
  text-align: center;
  border-radius: $border-radius;
  position: relative;

  &-icon {
    padding-left: 4rem;
  }

  &-close {
    padding-right: 4rem;
  }

  > i {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 1rem;
    background: rgba($white,.2);
  }

  &-success {
    background: $success-color;
    color: $white;
  }

  &-warning {
    background: $warning-color;
    color: $white;
  }

  &-error {
    background: $error-color;
    color: $white;
  }
}