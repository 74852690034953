// -----------------------------------------------------------------------------
// This file contains all styles related to the car categories component.
// -----------------------------------------------------------------------------

.car-categories {
  &--header {
    @include flex($flexContent:center,$flexWrap:wrap);
    margin-left: auto;
    margin-right: auto;

    label {
      line-height: 1.2em;
    }

    select {
      appearance: none;
      background: transparent no-repeat calc(100% - .25rem) 50%;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.416 8.121'%3e%3cpath style='line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:%23000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-opacity:1' d='M1.416 0 .002 1.414l6.707 6.707 6.707-6.707L12.002 0 6.709 5.293Z' fill='%23333'/%3e%3c/svg%3e");
      background-size: auto .625rem;
      border: 0;
      border-radius: $border-radius;
      color: $theme-color-1;
      padding: 0 1.75rem 0 .25rem;
      margin-left: .25rem;
      outline: 0;
      text-transform: uppercase;
      font-weight: 300;
      line-height: 1.2em;
      cursor: pointer;
      transition: .3s;

      &:hover {
        background-color: $theme-color-1;
        color: $white;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.416 8.121'%3e%3cpath style='line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:%23000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-opacity:1' d='M1.416 0 .002 1.414l6.707 6.707 6.707-6.707L12.002 0 6.709 5.293Z' fill='%23fff'/%3e%3c/svg%3e");
      }

      option {
        color: $text-color;
        background-color: $white;
      }
    }
  }

  &--list {
    @include flex($flexWrap:wrap);
    @include reset-list;
    gap: 0.625rem;

    @include media-breakpoint-up(sm) {
      gap: 1.5rem;
    }

    @include media-breakpoint-up(xxl) {
      gap: 2rem;
    }
  }

  &--item {
    flex-basis: 100%;
    max-width: 100%;
    aspect-ratio: 2.5;
    overflow: hidden;
    border-radius: calc($border-radius * 2);

    @include media-breakpoint-up(sm) {
      flex-basis: calc(50% - .75rem);
      max-width: calc(50% - .75rem);
      aspect-ratio: 1.75;
    }

    @include media-breakpoint-up(lg) {
      flex-basis: calc(33.33333% - 1.5rem);
      max-width: calc(33.33333% - 1.5rem);
    }

    @include media-breakpoint-up(xxl) {
      flex-basis: calc(33.33333% - 1.33333rem);
      max-width: calc(33.33333% - 1.33333rem);
    }


    &-img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-text {
      display: block;
      width: 100%;
      padding: 1rem;
      position: absolute;
      bottom: 50%;
      text-align: center;
      color: $white;
      font-weight: 500;
      transform: translateY(50%);
      transition: .3s;

      @include media-breakpoint-up(sm) {
        bottom: 0;
        transform: none;
      }

      strong {
        display: block;
        margin-bottom: .25rem;
        text-transform: uppercase;
        font-family: $font-header;
        font-size: 1.375rem;
        font-weight: 700;
      }
    }

    a {
      display: block;
      height: 100%;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 200%;
        background-image: linear-gradient(0deg, $black 50%, transparent 100%);
        opacity: .6;
        position: absolute;
        top: 0%;
        left: 0;
        transition: .3s;
      }

      @media (hover: hover) {
        &:hover,
        &:focus {
          &::before {
            top: -100%;
          }

          .car-categories--item-text {
            bottom: 50%;
            transform: translateY(50%);
          }
        }
      }
    }
  }
}