// -----------------------------------------------------------------------------
// This file contains all styles related to the main nav component.
// -----------------------------------------------------------------------------

.main-nav {
  width: 100%;
  height: 100%;
  padding-top: 3.625rem;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($black,.96);
  text-align: right;
  transform: translateX(100%);
  transition: .3s;

  @supports (backdrop-filter: blur(1rem)) {
    background: rgba($black,.8);
    backdrop-filter: blur(1rem);
  }

  @include media-breakpoint-up(md) {
    padding-top: 5.125rem;
  }

  &--list {
    display: block;
    @include reset-list;
    text-transform: uppercase;
    font-family: $font-header;
    font-size: 1.625rem;
    font-weight: 500;
    text-align: left;

    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }

  &--item {
    a {
      display: block;
      padding: .625rem 2rem;
      color: $white;

      &:hover,
      &:focus {
        color: $theme-color-1;
      }
    }

    ul {
      @include reset-list;
      padding: 0 0 .75rem 2.5rem;
      text-transform: none;
      font-family: $font-text;
      font-size: 1rem;
      font-weight: 400;

      a{
        padding: .25rem 0;
      }
    }
  }

  &--submenu {
    background: rgba($white,.1);
  }

  &.is-visible {
    transform: translateX(0);
  }
}