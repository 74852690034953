.table-privacy 
{
    border-collapse: collapse;
    width: 100%;

    & th tr 
    {
        width: 100%;
    }

    & th 
    {
        border: 1px solid gray;
        color: $white;
        background-color: grey;
    }

    & .th-adjust 
    {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & td
    {
        border: 1px solid gray;
    }

    & tbody tr:first-child 
    {
        border-top: none;
    }

    & a:hover  
    {
        color: $theme-color-4;
    }

    .trusted-partners 
    {
        min-width: 600px;
    }

    & .adjust-privacy 
        {
            min-height: 60px;
        }

}

.privacy-policy 
{
    a:hover 
    {
        color: $theme-color-4;
    }
}

@media only screen and (min-width: 320px) and (max-width: 800px) 
{
    .table-privacy 
    {
        & th 
        {
            min-width: 200px;
        }

    }

}
