// -----------------------------------------------------------------------------
// This file contains all styles related to the icons grid component.
// -----------------------------------------------------------------------------

.icons-grid {
  @include flex($flexWrap:wrap,$flexContent:space-between,$flexItems:flex-start);
  @include reset-list;

  &--item {
    flex-basis: 50%;
    padding: 2rem .5rem;
    text-align: center;

    @include media-breakpoint-up(md) {
      flex-basis: unset;
    }

    &-img {
      display: block;
      width: 4.75rem;
      height: 4.75rem;
      margin: .5rem auto;
    }

    &-text {
      display: block;
      font-size: .875rem;
      font-weight: 500;

      @include media-breakpoint-up(xl) {
        font-size: 1.125rem;
      }
    }
  }
}