// -----------------------------------------------------------------------------
// This file contains all styles related to the tabs component.
// -----------------------------------------------------------------------------

.nav {
  &-tabs {
    display: block;
    overflow: auto;
    text-align: center;
    white-space: nowrap;

    @include media-breakpoint-up(md) {
      @include flex($flexContent:center);
      overflow: hidden;
      white-space: normal;
    }
  }

  &-link {
    padding: 0.5rem 1rem;
    margin: 0 .0625rem;
    border: 0;
    border-radius: $border-radius 0 $border-radius 0;
    background: transparent;
    line-height: 1em;
    color: inherit;
    font-weight: 300;
    cursor: pointer;
    transition: .3s;

    &:hover:not(.active) {
      background: $theme-color-1;
      color: $white;
    }

    &.active {
      background: $black;
      color: $white;
      font-weight: 400;
      cursor: default;
    }
  }
}

.bg__black {
  .nav {
    &-link {  
      &:hover:not(.active) {
        background: rgba($white,.2);
        color: $white;
      }
  
      &.active {
        background: $theme-color-1;
        color: $black;
      }
    }
  }
}

.tab-pane {
  display: none;

  &.active {
    display: block;
  }
}

