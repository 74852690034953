// -----------------------------------------------------------------------------
// This file contains all styles related to the contact info component.
// -----------------------------------------------------------------------------


.contact-info {
  @include flex($flexWrap:wrap);
  row-gap: 1rem;
  column-gap: 2rem;
  padding: 0;
  margin: 0;
  font-family: $font-header;

  &-item {
    @include flex($flexWrap:wrap);

    @include on-event {
      svg {
        transform: scale(1.1);
        transition: .1s;
      }
    }

    svg {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: .625rem;
    }

    strong {
      padding-bottom: .25rem;
      color: $theme-color-1;
      font-size: .75rem;
      font-weight: 600;
      text-transform: uppercase;
    }

    a {
      color: $white;

      @include on-event {
        color: $theme-color-1;
      }
    }
  }

  &--photo {
    display: block;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    object-fit: cover;
  }
}