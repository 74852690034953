// -----------------------------------------------------------------------------
// This file contains all styles related to the form component.
// -----------------------------------------------------------------------------


.form {
  &-control {
    appearance: none;
    border: 1px solid $border-color;
    padding: .5rem 1.5rem;
    border-radius: $border-radius;
    color: $text-color;
    outline: 0;
    @include transition;
    
    &::placeholder {
      color: $text-color-light;
      font-style: italic;
    }

    &:hover {
      border-color: $theme-color-1;
      box-shadow: 1px 0 1px $theme-color-1, 0 1px 1px $theme-color-1, -1px 0 1px $theme-color-1, 0 -1px 1px $theme-color-1;
    }

    &:focus {
      border-color: $theme-color-1;
      box-shadow: 1px 0 1px $theme-color-1, 0 1px 1px $theme-color-1, -1px 0 1px $theme-color-1, 0 -1px 1px $theme-color-1;
    }

    &-lg {
      padding-top: 1.125rem;
      padding-bottom: 1.125rem;
    }
  }

  &-select {
    width: 100%;
    appearance: none;
    background: transparent no-repeat calc(100% - .875rem) 50%;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.416 8.121'%3e%3cpath style='line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:%23000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-opacity:1' d='M1.416 0 .002 1.414l6.707 6.707 6.707-6.707L12.002 0 6.709 5.293Z' fill='%2307E34F'/%3e%3c/svg%3e");
    background-size: auto .5rem;
    border: 0;
    border-bottom: 1px solid $border-color;
    border-radius: 0;
    color: $text-color;
    padding: .625rem 2.25rem .625rem .625rem;
    margin-bottom: .625rem;
    outline: 0;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: .3s;

    &:invalid {
      font-style: italic;
    }

    &:hover,
    &:focus {
      color: $theme-color-1;
      border-bottom: 1px solid $theme-color-1;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.416 8.121'%3e%3cpath style='line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:%23000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-opacity:1' d='M1.416 0 .002 1.414l6.707 6.707 6.707-6.707L12.002 0 6.709 5.293Z' fill='%2307E34F'/%3e%3c/svg%3e");
    }

    option {
      color: $text-color;
      background-color: $white;
    }
  }
  
  &-input {
    .form-control {
      width: 100%;
      background: transparent;
      border: 0;
      border-bottom: 1px solid $border-color;
      border-radius: 0;
      color: $text-color;
      padding: .625rem;
      margin-bottom: .625rem;
      outline: 0;
      font-weight: 300;
      transition: .3s;

      &::placeholder {
        font-style: italic;
      }

      &:hover,
      &:focus {
        color: $theme-color-1;
        outline: 0;
        box-shadow: none;
        border: 0;
        border-bottom: 1px solid $theme-color-1;
       }
    }
  }

  &-range {
    position: relative;

    &-5-items {
      .form-range-thumb {
        min-width: 20%;
      }
    }

    &-lg {
      .form-range-thumb {
        min-width: 5rem;
      }
    }

    &-input {
      width: 100%;
      height: 2.5rem;
      background: $gray-200;
      border-radius: $border-radius;
      position: relative;
    }

    &-thumb {
      display: block;
      min-width: 2.5rem;
      height: 2.5rem;
      line-height: 2.5rem;
      padding: 0 .5rem;
      background: $theme-color-4;
      box-shadow: 0 0 .5rem rgba($black,.4);
      border-radius: $border-radius;
      color: $white;
      text-align: center;
      position: absolute;
      bottom: 0;
      z-index: 2;
    }
    
    &-track {
      height: 100%;
      background: $theme-color-4;
      border-radius: $border-radius;
    }

    &-steps {
      @include flex($flexItems:stretch);
      width: 100%;
      height: 2.5rem;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      color: rgba($black,.4);
      
      option {
        @include flex($flexContent:center,$flexItems:stretch);
        flex: 1;
        line-height: 2.5rem;
      }
    }
  }

  &-radio-special {
    .form-check {
      @include flex;
      border-radius: $border-radius;
      background: $gray-200;
      position: relative;

      label {
        @include flex($flexContent:center);
        flex: 1;
        gap: .5rem;
        padding: 0.625rem;
        font-size: .75rem;
        font-weight: 400;
        line-height: 1.125rem;
        cursor: pointer;
        position: relative;
        z-index: 2;
        transition: .3s;

        &::before {
          color: $text-color;
          font-size: 2.125rem;

          @include media-breakpoint-down(sm) {
            width: 100%;
          }

          @include media-breakpoint-up(sm) {
            color: $theme-color-1;
          }
        }

        &:hover {
          @include media-breakpoint-up(sm) {
            color: $theme-color-4;
          }
        }
      }

      input {
        @extend .visually-hidden;

        &:checked + label {
          color: $white;
          background-color: $theme-color-4;
          border-radius: 10px;
          
          &::before {
            color: $white;
            
          }
        }

        @for $i from 1 through 5{
          &:nth-of-type(#{$i}){
            &:checked ~ .form-check-marker {
              left: #{(-1+$i)*(100%/5)} !important;
              background-color: $theme-color-4;
            }
          }
        }
      }

      &-marker {
        width: 20%;
        height: 100%;
        background: $theme-color-1;
        border-radius: $border-radius;
        box-shadow: 0 0 .75rem rgba($black,.3);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        transition: .3s;
      }
    }

    &-3-items {
      .form-check {
        input {  
          @for $i from 1 through 3{
            &:nth-of-type(#{$i}){
              &:checked ~ .form-check-marker {
                left: #{(-1+$i)*(100%/3)} !important;
              }
            }
          }
        }
  
        &-marker {
          width: 33.33333%;
        }
      }

      &.form-radio-special-tabs {
        .form-check {
          &::before,
          &::after {
            content: '';
            width: 0;
            height: 100%;
            border-right: 1px solid $border-color;
            position: absolute;
            z-index: 0;
            top: 0;
          }

          &::before {
            left: 33.33333%;
          }

          &::after {
            left: 66.66666%;
          }
        }
      }
    }

    &-tabs {
      .form-check {
        border-radius: 0;

        label {
          padding: .625rem;
          font-size: .875rem;
          text-align: center;

          @include media-breakpoint-up(xl) {
            padding: 1rem;
            font-size: 1rem;
          }
        }
      }

      .form-check-marker {
        border-radius: 0;
      }

      .form-check-label {
        color: $text-color;
      }
    }
  }

  &-required {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: .375rem;
      height: .375rem;
      position: absolute;
      left: .75rem;
      top: 1.5rem;
      border-radius: $border-radius;
      border: 1px solid $theme-color-1;
    }

    &-label {
      padding-left: 1rem;

      &::before {
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}

textarea {
  min-height: 15rem;
}

label.form-label,
legend.form-label,
.form-label label {
  display: block;
  font-family: $font-text;
  color: $text-color-light;
  font-size: .875rem;
  font-weight: 300;
  padding-bottom: .5rem;
}

label.form-label-sm,
.form-label-sm label,
legend.form-label {
  font-size: .625rem;
  padding-bottom: .375rem;
}