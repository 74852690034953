// -----------------------------------------------------------------------------
// This file contains all styles related to the pagination component.
// -----------------------------------------------------------------------------

.pagination {
  display: inline-flex;
  @include reset-list;
  margin: 2rem auto;
  overflow: hidden;
  border-radius: $border-radius;

  .page {
      &-item {
        &:first-of-type,
        &:last-of-type {
          .page-link {
            flex-wrap: wrap;
            font-size: 0;
            color: transparent;

            &::before {
              width: 100%;
              font-family: $font-icon;
              font-size: 1rem;
              color: $theme-color-1;
            }
          }

          &:not(.disabled) {
            .page-link {
              &:hover {
                &::before {
                  color: $white;
                }
              }
            }
          }
        }

        &:first-of-type {
            .page-link {
                &::before {
                    content: '\0044';
                    transform: rotate(90deg);
                }
            }
        }

        &:last-of-type {
            .page-link {
                &::before {
                    content: '\0044';
                    transform: rotate(-90deg);
                }
            }
        }

        .page-link {
          @include flex($flexContent:center,$flexItems:center);
          flex-basis: 2.5rem;
          min-width: 2.5rem;
          height: 2.5rem;
          line-height: 2.5rem;
          color: $theme-color-1;
          text-align: center;
          background-color: $border-color-light;
    
          &:hover {
            background-color: $black;
            border-color: $theme-color-2;
            color: $white;
          }
        }

        &.active {
            .page-link {
                background-color: $theme-color-1;
                border-color: $theme-color-1;
                color: $white;
            }
        }

        &.disabled {
          .page-link {
            background-color: $border-color-light;
            color: $border-color-dark;

            &::before {
                color: $border-color-dark;
            }
          }
        }

        @include media-breakpoint-down(sm) {
          display: none
        }

        &:first-of-type,
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-last-of-type(2),
        &:last-of-type {
          @include media-breakpoint-down(sm) {
            display: block;
            width: 3.5rem;
          }
        }

        &:nth-of-type(3):not(.page-item:nth-last-of-type(2)) {
          &::before {

            @include media-breakpoint-down(sm) {
              content: '...';
              display: block;
              width: 100%;
              height: 2.5rem;
              line-height: 2.5rem;
              background-color: $border-color-light;
            }
          }

          span,
          a {
            @include media-breakpoint-down(sm) {
              display: none;
            }
          }
        }

      }
  }
}