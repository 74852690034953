// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site.
// -----------------------------------------------------------------------------

.header {
  width: 100%;
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  background-color: transparent;
  @include transition;

  >.container-md {
    position: relative;
    z-index: 10;
  }

  &--logo {
    max-width: 10rem;
    height: auto;

    @include media-breakpoint-up(xsm) {
      max-width: 11rem;
    }

    @include media-breakpoint-up(lg) {
      max-width: 12rem;
    }
  }

  &--search {
    width: calc(100vw - 7rem);
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    border-radius: 2rem;
    background: $white;
    transform: translateY(-5rem);
    transition: .3s;

    @include media-breakpoint-up(md) {
      width: calc(100% - 1rem);
    }

    @include media-breakpoint-up(lg) {
      width: 100%;
      max-width: 40rem;
      position: relative;
      background: transparent;
      transform: translateY(0);
    }

    &.is-visible {
      transform: translateY(0);
    }

    &-input {
      width: calc(100% - 3.125rem);
      height: 3.125rem;
      padding: 0 0 0 2.875rem;
      background-color: transparent;
      border: 1px solid rgba($white, .15);
      border-radius: 2rem;
      position: relative;
      z-index: 1;
      outline: 0;
      color: $theme-color-1;
      font-weight: 500;
      font-style: italic;
      transition: .3s;

      @include media-breakpoint-up(lg) {
        width: 100%;
        padding: 0 3.5rem;
        z-index: unset;
        color: $white;
      }

      &::placeholder {
        color: $theme-color-1;
        font-weight: 500;
        font-style: italic;

        @include media-breakpoint-up(lg) {
          color: $white;
        }
      }

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
        -webkit-appearance: none;
      }
    }

    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.125rem;
      height: 3.125rem;
      padding: 0;
      color: $theme-color-1;
      background-color: transparent;
      border: 0;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      font-size: 1.5rem;
      cursor: pointer;
      transition: .3s;

      @include media-breakpoint-up(lg) {
        color: $white;
      }

      &:hover,
      &:focus {
        color: $text-color;
      }
    }

    &-select {
      appearance: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      background: transparent no-repeat calc(100% - 1rem) 50%;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.999 19.999'%3e%3cpath style='line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:%23000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:%23000;solid-opacity:1' d='M9.737-.262c-1.045 0-1.781.72-2.201 1.615H.739a1 1 0 1 0 0 2h6.789c.416.904 1.157 1.631 2.209 1.631s1.793-.727 2.209-1.63h6.793a1 1 0 1 0 0-2h-6.8c-.42-.896-1.157-1.616-2.202-1.616Zm0 2c.348 0 .598.265.61.61a1 1 0 0 0 0 .027c-.012.344-.262.61-.61.61s-.598-.266-.61-.61a1 1 0 0 0 .003-.03c.013-.342.26-.607.607-.607Zm5.164 5.377c-1.045 0-1.781.72-2.201 1.615H.739a1 1 0 1 0 0 2h11.953c.416.904 1.158 1.631 2.209 1.631 1.052 0 1.793-.727 2.209-1.63h1.629a1 1 0 1 0 0-2h-1.637c-.42-.896-1.156-1.616-2.201-1.616Zm0 2c.348 0 .598.265.61.61a1 1 0 0 0 0 .027c-.012.344-.262.61-.61.61s-.598-.266-.61-.61a1 1 0 0 0 .003-.03c.013-.342.26-.607.607-.607ZM4.573 14.492c-1.045 0-1.781.72-2.201 1.615H.739a1 1 0 1 0 0 2h1.625c.416.904 1.157 1.631 2.209 1.631 1.051 0 1.793-.727 2.209-1.63h11.957a1 1 0 1 0 0-2H6.774c-.42-.896-1.156-1.616-2.201-1.616Zm0 2c.348 0 .598.265.61.61a1 1 0 0 0 0 .027c-.012.344-.262.609-.61.609s-.598-.265-.61-.61a1 1 0 0 0 .002-.029c.013-.342.26-.607.608-.607z' color='%23000' font-weight='400' font-family='sans-serif' overflow='visible' fill='%23bababa' stroke='none' transform='translate(.262 .262)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3e%3c/svg%3e");
      background-size: auto 1.125rem;
      border: 0;
      border-radius: 0 3rem 3rem 0;
      color: transparent;
      padding: 0 1.5rem;
      outline: 0;
      font-weight: 300;
      cursor: pointer;
      transition: .3s;

      @include media-breakpoint-up(lg) {
        padding: 0.5rem 2.25rem 0.5rem 1rem;
        color: $white;
        background: transparent no-repeat calc(100% - .875rem) 50%;
        background-size: auto .625rem;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.416 8.121'%3e%3cpath style='line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:%23000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-opacity:1' d='M1.416 0 .002 1.414l6.707 6.707 6.707-6.707L12.002 0 6.709 5.293Z' fill='%23fff'/%3e%3c/svg%3e");
      }

      &:hover {
        @include media-breakpoint-up(lg) {
          background-color: $theme-color-1;
          color: $white;
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.416 8.121'%3e%3cpath style='line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:%23000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-opacity:1' d='M1.416 0 .002 1.414l6.707 6.707 6.707-6.707L12.002 0 6.709 5.293Z' fill='%23fff'/%3e%3c/svg%3e");
        }
      }

      option {
        color: $text-color;
        background-color: $white;
      }
    }

    &:hover,
    &:focus-within {
      @include media-breakpoint-up(lg) {
        .header--search-input {
          background: $white;
          border-color: $white;
          color: $theme-color-1;
          outline: 0;

          &::placeholder {
            color: $theme-color-1;
          }
        }

        .header--search-btn:not(:hover) {
          color: $theme-color-1;
          outline: 0;
        }

        .header--search-select:not(:hover) {
          color: $text-color;
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.416 8.121'%3e%3cpath style='line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:%23000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-opacity:1' d='M1.416 0 .002 1.414l6.707 6.707 6.707-6.707L12.002 0 6.709 5.293Z' fill='%23333'/%3e%3c/svg%3e");
        }
      }
    }

    &-results {
      width: calc(100vw - 1.25rem);
      position: absolute;
      top: calc(100% + .25rem);
      right: -5.625rem;
      margin: 0 auto;
      border-radius: 1.625rem;
      background: $white;
      color: $text-color;
      overflow: hidden;
      box-shadow: 0 0 2rem rgba($black, .2);

      @include media-breakpoint-up(md) {
        width: calc(100vw - 7rem);
        right: 0;
      }

      @include media-breakpoint-up(md) {
        width: calc(100% - 1rem);
      }

      @include media-breakpoint-up(lg) {
        left: 0;
        width: 100%;
        max-width: 40rem;
      }

      .container {
        max-width: 100%;
        padding: 0;
        max-height: 70vh;
        overflow: auto;

        .row {
          margin: 0;
        }
      }

      h2 {
        padding: .5rem 1rem .125rem 1rem;
        margin: 0;
        font-size: .625rem;
        text-transform: uppercase;
        font-weight: 400;
        color: $text-color-light;

        &:first-of-type {
          padding-top: 1rem;
        }

        @include media-breakpoint-up(md) {
          font-size: 1rem;
        }
      }

      ul {
        @include reset-list;

        a {
          display: block;
          padding: .875rem 1rem;
          font-size: .75rem;
          color: $text-color;

          @include media-breakpoint-up(md) {
            font-size: 1rem;
          }

          @include on-event {
            background: $theme-color-1;
            color: $white;
          }
        }
      }
    }
  }

  &--icons {
    @include flex($flexContent:flex-end);

    .btn[class*=icon-] {
      justify-content: center;
      width: 3.125rem;
      height: 3.125rem;
      padding: 0;
      color: $white;


      &::before {
        margin: 0;
        font-size: 1.375rem;
      }

      &:hover,
      &:focus {
        color: $theme-color-1;
      }
    }
  }

  //start change on scroll

  &.fixed {
    background-color: $white;
    box-shadow: 0 0 1rem rgba($black, .2);

    .logo__white {
      fill: $theme-color-4;
    }

    .btn[class*=icon-] {
      color: $black;

      &:hover,
      &:focus {
        color: $theme-color-1;
      }
    }

    .header--search-input {
      border: 1px solid rgba($black, .15);

      &::placeholder {
        color: $black;
      }
    }

    .header--search-btn {
      color: $black;

      &:hover,
      &:focus {
        color: $theme-color-1;
      }
    }

    .main-nav {
      background: rgba(255, 255, 255, 0.8);

      a {
        color: $black;

        &:hover,
        &:focus {
          color: $theme-color-1;
        }
      }
    }

    &:hover,
    &:focus-within {
      @include media-breakpoint-up(lg) {
        .header--search-input {
          border-color: $black;
        }
      }
    }
  }

  //end change on scroll
}

//subpage header extends main header on scroll
.subpage {
  .header {
    @extend .fixed;
  }
}
