// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $theme-color-1;
  text-decoration: none;
  @include transition;

  @include on-event {
    color: $text-color;
  }
}

main,
aside {
  p,
  div,
  span,
  li
  input,
  textarea,
  ::placeholder {
    @include media-breakpoint-down(xl) {
      font-size: .875rem;
    }
  }
}