// -----------------------------------------------------------------------------
// This file contains all site-wide Sass variables.
// -----------------------------------------------------------------------------

// Color system

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

// Basic colors
$theme-color-1: #07E34F !default;
$theme-color-2: #ffb900 !default;
$theme-color-3: #E9F8FF !default;
$theme-color-4: #0B23FA !default;

$body-bg-color: $white !default;

$text-color: #333333 !default;
$text-color-light: #a7a7a7 !default;
$text-color-dark: $black !default;

$success-color: green !default;
$warning-color: $theme-color-2 !default;
$error-color: red !default;

// Fonts
$font-text: 'Roboto', 'Open Sans', 'Helvetica', 'Arial', sans-serif !default;
$font-header: 'Exo2', 'Open Sans', 'Helvetica', 'Arial', sans-serif !default;
$font-icon: 'Carfin' !default;

$default-text-size: 1rem !default;

// Borders
$border-color: #e2e2e2 !default;
$border-color-light: #f2f2f2 !default;
$border-color-dark: #d1d1d1 !default;
$border-radius: 0.5rem;

// Container
$max-width: 1374px;

// Breakpoints
$grid-breakpoints: (
  xs: 0,
  xsm: 360px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1201px,
  xxl: $max-width,
  hd: 1921px
);

// Spacers / grid gutter
$grid-gutter-width: 2rem;
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 5,
  8: $spacer * 6,
  9: $spacer * 7
) !default;


/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;
