// -----------------------------------------------------------------------------
// This file contains all styles related to the products list component.
// -----------------------------------------------------------------------------

.product-list {
  @include flex($flexContent: flex-start, $flexItems: stretch, $flexWrap: wrap);
  @include reset-list;
  column-gap: 1rem;
  row-gap: 1rem;

  @include media-breakpoint-up(md) {
    row-gap: 3rem;
  }

  @include media-breakpoint-up(xxl) {
    column-gap: 1.875rem;
  }

  &--header{
    @include media-breakpoint-up(lg) {
      color: $white;
    }
  }

  &.cols-4 {
    .product-list--item {
      @include media-breakpoint-up(xxl) {
        flex-basis: calc(25% - 1.41rem);
        max-width: calc(25% - 1.41rem);
      }
    }
  }

  &--item {
    flex: 1;
    flex-basis: 100%;
    max-width: 100%;
    padding: 1rem;
    border-radius: $border-radius;
    position: relative;
    background: $white;
    text-align: center;
    font-size: .875rem;
    transition: .3s;

    @include media-breakpoint-up(md) {
      flex-basis: calc(50% - .5rem);
      max-width: calc(50% - .5rem);

      &.single{
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    @include media-breakpoint-up(lg) {

      &.single{
        flex-basis: 100%;
        max-width: 100%;
        display: flex;
        &>div:first-of-type{
          flex-grow: 1;
          margin-right: 1rem;
        }
        &>div:last-of-type{
          display: flex;
          flex-direction: column;
          min-width: 22rem;
          .product-list--item-specs{
            margin-top: 2.3125rem;
            display: flex;
            flex-direction: column;
            max-height: none;
            flex-wrap: nowrap;
            align-items: flex-start;
          }
          .btn-lg{
            margin: auto 0 0 0;
            width: 100%;
          }
        }
      }
    }

    @include media-breakpoint-up(xl) {
      flex-basis: calc(33.33333% - .67rem);
      max-width: calc(33.33333% - .67rem);
      font-size: 1rem;
    }

    @include media-breakpoint-up(xxl) {
      flex-basis: calc(33.33333% - 1.25rem);
      max-width: calc(33.33333% - 1.25rem);
    }

    &-img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: $border-radius;
      overflow: hidden;
      object-fit: cover;
      aspect-ratio: 16/10.5;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-specs {
      @include flex($flexWrap: wrap);
      @include reset-list;
      margin: .375rem -.75rem;
      text-align: left;
      min-height: 6.5rem;
      align-content: space-between;

      li {
        @include flex;
        flex-basis: 50%;
        padding: 0 .75rem;
        margin: .375rem 0;
        white-space: nowrap;

        i {
          font-size: 1.125rem;
          color: $text-color-light;
        }
      }
    }

    hr {
      border: 0;
      border-top: 1px solid $border-color;
    }

    &-info {
      @include flex($flexItems:stretch);
      @include reset-list;
      margin: .375rem -.5rem;
      text-align: left;

      @include media-breakpoint-up(lg) {
        margin: .375rem -.75rem;
      }

      li {
        @include flex($flexWrap:wrap,$flexItems:flex-start);
        max-width: 33.33333%;
        padding: 0 .5rem;
        margin: .375rem 0;

        @include media-breakpoint-up(sm) {
          flex-basis: 33.33333%;
        }

        @include media-breakpoint-up(lg) {
          padding: 0 .75rem;
          border-left: 1px solid $border-color;
        }

        &:first-of-type {
          border-left: 0;
        }

        span {
          flex-basis: 100%;
          text-transform: uppercase;
          font-size: .75rem;
          font-weight: 500;
          color: $text-color-light;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .icon-star-full {
          color: $theme-color-2;
          font-size: 1.125rem;
        }
      }
    }

    &-extra {
      @include flex($flexContent:flex-end,$flexWrap:wrap);
      gap: .25rem;
      @include reset-list;
      padding: 0 1.5rem;
      margin: -.625rem;
      position: absolute;
      right: 0;
      color: $white;
      line-height: 1.2em;
      font-size: .625rem;
      font-weight: 500;
      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        top: 0;
      }

      li {
        padding: .25rem .5rem;
        border-radius: calc($border-radius / 2);
      }

      &__blue {
        background: $theme-color-1;
      }

      &__yellow {
        background: $theme-color-2;
      }

      &__black {
        background: $black;
      }
    }

    &-btn {
      width: 16rem;
      max-width: 100%;
      padding: 0;
      margin: auto;
      border-color: $theme-color-1;
      position: relative;
      font-size: .875rem;

      > span {
        @include flex($flexContent:center,$flexItems:flex-end);
        width: 100%;
        padding: .625rem 1rem;
        color: $theme-color-1;
        opacity: 1;
        transition: .2s;

        &:last-of-type {
          @include flex($flexContent:center,$flexItems:center);
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          background: $theme-color-1;
          color: $white;
          line-height: 1em;
          font-size: 1.125rem;
          font-weight: 500;
          transition: .3s;
        }
      }

      strong {
        margin-right: .25rem;
        line-height: .875em;
        font-family: $font-header;
        font-weight: 700;
        font-size: 2.25rem;
        transition: .1s;
      }

      &__top {
        z-index: 1;
      }
    }

    &-url {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    @media (hover: hover) {
      &:hover {
        box-shadow: 1px 0 0 $theme-color-1,
          0 1px 0 $theme-color-1,
          -1px 0 0 $theme-color-1,
          0 -1px 0 $theme-color-1,
          2px 0 0 $theme-color-1,
          0 2px 0 $theme-color-1,
          -2px 0 0 $theme-color-1,
          0 -2px 0 $theme-color-1,
          0 0 2rem rgba($black,.2);

        .product-list--item-btn {
          background: $theme-color-1;
          border-color: $theme-color-1;

          > span {
            color: rgba($white,.7);
            opacity: 0;

            &:last-of-type {
              opacity: 1;
              color: $white;
            }
          }

          strong {
            color: $white;
          }
        }
      }
    }
  }

  &--count {
    border: 1px solid $white;
    padding: .375rem;
    border-radius: $border-radius;
    font-family: $font-text;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2em;
  }
}
