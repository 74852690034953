// -----------------------------------------------------------------------------
// This file contains all styles related to the hero component.
// -----------------------------------------------------------------------------

.hero {
  position: relative;

  &--texts {
    @include flex;
    min-height: 100vh;
    padding-top: 3.5rem;
    position: relative;
    z-index: 1;

    @include media-breakpoint-up(md) {
      padding-top: 5rem;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 5rem;
    }
  }

  &--configurator {
    background-color: $white;
    border-radius: calc($border-radius * 2);
    padding: 1rem;
    padding-bottom: 0;
    margin-bottom: -2rem;
    box-shadow: 0 0 2rem rgba($black, .2);
    z-index: 10;

    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
      background-color: transparent;
      box-shadow: unset;
      border-radius: 0;
    }

    h1 {
      text-align: center;
      font-size: clamp(1rem, 3.5vw, 1.75rem);

      @include media-breakpoint-up(lg) {
        text-align: left;
        color: $white;
        text-transform: none;
      }

      @include media-breakpoint-up(xl) {
        font-size: 2.25rem;
      }
    }

    &-form {
      width: 100%;
      background-color: $white;
      overflow: hidden;

      @include media-breakpoint-up(lg) {
        border-radius: ($border-radius * 2) 0 ($border-radius * 2) 0;
        box-shadow: 0 0 3rem 0 rgba($black, .4);
      }
    }

    &-type {
      width: 100%;

      .form-check {
        @include flex;
        border-radius: $border-radius;
        background: $gray-200;
        position: relative;

        @include media-breakpoint-up(sm) {
          background: transparent;
        }

        &-label {
          @include flex($flexContent:center);
          flex: 1;
          gap: .5rem;
          padding: 0.625rem;
          font-size: .75rem;
          font-weight: 400;
          line-height: 1.125rem;
          cursor: pointer;
          position: relative;
          z-index: 2;
          transition: .3s;

          @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
            height: 3.5rem;
            font-size: 0;
            line-height: 0;
          }

          &::before {
            color: $text-color;
            font-size: 2.125rem;

            @include media-breakpoint-down(sm) {
              width: 100%;
            }

            @include media-breakpoint-up(sm) {
              color: $theme-color-4;
            }
          }

          &:hover {
            @include media-breakpoint-up(sm) {
              color: $theme-color-4;
            }
          }
        }

        input {
          @extend .visually-hidden;

          &:checked+.form-check-label {
            color: $theme-color-1;
            background-color: $theme-color-4;
            border-radius: 10px;

            @include media-breakpoint-up(sm) {
              color: $white;
            }

            &::before {
              color: $theme-color-1;

              @include media-breakpoint-up(sm) {
                color: $white;
              }
            }
          }

          @for $i from 1 through 3 {
            &:nth-of-type(#{$i}) {
              &:checked~.form-check-marker {
                left: #{(-1+$i)*(100%/3)} !important;
                background-color: $theme-color-4;
              }
            }
          }
        }

        &-marker {
          width: 33.33333%;
          height: 100%;
          background: $black;
          border-radius: $border-radius;
          box-shadow: 0 0 .75rem rgba($black, .3);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          transition: .3s;

          @include media-breakpoint-up(sm) {
            background: $theme-color-1;
          }
        }
      }
    }

    .cols-2 {
      @include flex($flexContent:space-between, $flexWrap:wrap);
      gap: .5rem;

      .col {
        flex-basis: 100%;
        max-width: 100%;


        @include media-breakpoint-up(sm) {
          flex-basis: calc(50% - .25rem);
          max-width: calc(50% - .25rem);

        }
      }

      .form-buttons {
        flex-basis: 100%;
        max-width: 100%;
        text-align: right;
      }
    }

    &-extra-info {
      overflow: hidden;
      max-height: 0;
      transition: .3s;

      &.is-visible {
        max-height: 50rem;
        overflow: visible;
      }
    }

    &-price {
      font-size: .75rem;
      text-transform: uppercase;
      color: $theme-color-1;

      >span {
        padding-bottom: .375rem;
        color: $white;
      }

      strong {
        line-height: 1em;
        font-size: 2.75rem;
        font-weight: 900;
        white-space: nowrap;

        span {
          font-size: .75rem;
        }
      }
    }

    &-form--page {
      display: block;
      border-radius: $border-radius;
      box-shadow: 0 0 3rem rgba($black, .4);
      font-size: .875rem;

      @include media-breakpoint-down(lg) {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        border-radius: $border-radius $border-radius 0 0;
      }

      .hero--configurator-type {
        flex-basis: 100%;
        max-width: 100%;
        @extend .mb-0;
        background: $gray-200;
        border-radius: $border-radius;

        @include media-breakpoint-up(sm) {
          flex-basis: calc(50% - .5rem);
          max-width: calc(50% - .5rem);
        }

        @include media-breakpoint-up(lg) {
          flex-basis: calc(20% - .5rem);
          max-width: calc(20% - .5rem);
        }

        .form-check-label {
          flex-wrap: wrap;
          height: auto;
          gap: 0;
          padding: .25rem;
          font-size: 0;
          line-height: 0;

          &::before {
            width: 100%;
          }
        }
      }

      .cols-2 {
        @include flex($flexItems:center, $flexWrap:wrap);
        gap: .625rem;

        .form-select,
        .form-input {
          flex-basis: 100%;
          max-width: 100%;
          margin-bottom: 0;

          @include media-breakpoint-up(sm) {
            flex-basis: calc(50% - .5rem);
            max-width: calc(50% - .5rem);
          }

          @include media-breakpoint-up(lg) {
            flex-basis: calc(20% - .5rem);
            max-width: calc(20% - .5rem);
          }

          .form-control {
            margin-bottom: 0;
          }
        }

        .form-buttons {
          flex: 1;
          text-align: right;

          @extend .mt-0;

          .btn {
            width: 100%;
            @extend .btn-md;
          }
        }
      }
    }
  }

  &--text {
    @include flex($flexContent:center);
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 11rem);
    position: relative;
    z-index: 10;
    color: $white;

    @include media-breakpoint-up(xsm) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @include media-breakpoint-up(lg) {
      min-height: auto;
      padding-left: 0;
      padding-right: 0;
    }

    h2 {
      margin: 0;
      font-size: clamp(1.75rem, 6.5vw, 3.375rem);
      text-transform: none;

      @include media-breakpoint-up(lg) {
        font-size: clamp(0.875rem, 3.75vw, 1.625rem);
      }
    }
  }

  &--carousel {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    background-color: $black;

    .swiper {
      width: 100%;
      height: 100%;
    }

    &-img {
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.4;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:not(img) {
        height: 100%;
      }
    }

    &-pagination {
      display: inline-flex;
      justify-content: center;
      gap: 1.25rem;
      position: absolute;
      bottom: 10rem;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 10;

      @include media-breakpoint-up(lg) {
        bottom: 2.5rem;
      }

      >.swiper-pagination-bullet {
        flex-basis: .625rem;
        height: .625rem;
        border-radius: 50%;
        background: $white;
        cursor: pointer;

        &-active {
          background: $theme-color-1;
          cursor: default;
        }
      }
    }
  }

  &--page {
    .hero {
      &--texts {
        min-height: auto;
        padding-top: 1rem;

        @include media-breakpoint-up(md) {
          padding-top: 3rem;
        }

        @include media-breakpoint-up(lg) {
          padding-top: 5rem;
          padding-bottom: 2rem;
        }
      }
    }
  }
}
