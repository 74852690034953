// -----------------------------------------------------------------------------
// This file contains all styles related to the calculator component.
// -----------------------------------------------------------------------------

.calculator {
  &--img {
    &-lg {
      position: absolute;
      left: 65%;
      top: -1rem;
      z-index: 1;
    }
  }

  &--card {
    @extend .border-default;
    overflow: hidden;
    position: relative;
    z-index: 2;
    box-shadow: 0 0 2rem rgba($black,.2);
  }
}