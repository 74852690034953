// -----------------------------------------------------------------------------
// This file contains all styles related to the newsletter signup component.
// -----------------------------------------------------------------------------

.newsletter-signup {
  @include flex($flexItems:stretch, $flexWrap:wrap);
  column-gap: 1.875rem;

  input {
    padding: 0.5rem 1rem;
    font-size: 1.125rem;
    font-weight: 300;
  }
}