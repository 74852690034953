// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.btn {
  @include flex(inline-flex, flex-start, center);
  padding: .625rem 1.75rem;
  border: 1px solid;
  border-radius: $border-radius;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.125em;
  text-transform: uppercase;
  cursor: pointer;
  @include transition;

  &-primary {
    background-color: $theme-color-1;
    border-color: $theme-color-1;
    color: $white;

    &:hover,
    &:focus {
      background-color: transparent;
      color: $theme-color-1;
      border-color: $theme-color-1;
    }

    &-outline {
      border-color: $border-color-dark;
      color: $black;
      background: $white;

      &:hover,
      &:focus {
        background-color: $theme-color-1;
        color: $white;
        border-color: $theme-color-1;
      }
    }
  }

  &-secondary {
    background-color: $white;
    border-color: $white;
    color: $text-color;

    &:hover,
    &:focus {
      background-color: transparent;
      color: $white;
      border-color: $white;
    }

    &-outline {
      border-color: $theme-color-1;
      color: $theme-color-1;
      background: transparent;

      &:hover,
      &:focus {
        background-color: $theme-color-1;
        color: $white;
        border-color: $theme-color-1;
      }
    }
  }

  &-other {
    background-color: $white;
    border-color: $white;
    color: $text-color-light;

    &:hover,
    &:focus {
      background-color: transparent;
      color: $white;
      border-color: $white;
    }

    &-outline {
      border-color: $text-color-light;
      color: $text-color-light;
      background: transparent;

      &:hover,
      &:focus {
        background-color: $text-color-light;
        color: $white;
        border-color: $text-color-light;
      }
    }
  }

  &-link {
    padding: 0;
    border: 0;
    background: transparent;
    text-transform: none;
    font-size: inherit;
    color: $theme-color-1;

    &:hover,
    &:focus {
      color: $text-color;
    }
  }

  &-md {
    padding: .625rem 1.75rem;
    border-width: 1px;
    font-size: 1rem;
  }

  &-lg {
    border-width: 2px;
    padding: 1rem 2.5rem;
    font-size: 1.125rem;
    line-height: 1.125em;
    text-transform: uppercase;
  }

  &-xl {
    border-width: 2px;
    padding: 1.25rem 2.5rem;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.125em;
    text-transform: uppercase;
  }

  &[class*='icon-'] {
    &::before {
      font-size: 1.25rem;
    }

    *:not(.visually-hidden) {
      margin-left: .625rem;
    }
  }
}
