// -----------------------------------------------------------------------------
// This file contains all styles related to the logo component.
// -----------------------------------------------------------------------------

.logo {
  &__white {
    fill: $white;
  }

  &__green {
    fill: $theme-color-1;
  }

  &__blue {
    fill: $theme-color-4;
  }

  &__black {
    fill: $black;
  }

  &__green 
  {
    fill: $theme-color-1;
  }
}