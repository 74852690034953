// -----------------------------------------------------------------------------
// This file contains styles that are specific to the Blog page.
// -----------------------------------------------------------------------------

.blog {
  &-list {
    &--item {
      height: 100%;
      border-radius: $border-radius;
      position: relative;
      transition: .3s;

      @include on-event {
        @include media-breakpoint-up(md) {
          box-shadow: 1px 0 0 $theme-color-1, 
            0 1px 0 $theme-color-1, 
            -1px 0 0 $theme-color-1, 
            0 -1px 0 $theme-color-1,
            2px 0 0 $theme-color-1, 
            0 2px 0 $theme-color-1, 
            -2px 0 0 $theme-color-1, 
            0 -2px 0 $theme-color-1,
            0 0 2rem rgba($black,.2);
        }
      }
    }

    &--photo {
      display: block;
      width: 100%;
      aspect-ratio: 16/9;
      object-fit: cover;
      border-radius: $border-radius;
      overflow: hidden;

      &-alt {
        aspect-ratio: 16/6;
      }
    }

    &--link {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
    }
  }

  &-post {
    &--photo {
      display: block;
      width: 100%;
      aspect-ratio: 16/9;
      object-fit: cover;
      border-radius: $border-radius;
      overflow: hidden;
    }
  }
}