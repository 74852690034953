// -----------------------------------------------------------------------------
// This file contains all styles related to the carousel component.
// -----------------------------------------------------------------------------

.carousel {
  &--btn {
    @include flex($flexContent:center);
    width: 2.5rem;
    height: 2.5rem;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 11;
    font-size: 1.5rem;
    color: $text-color;
    cursor: pointer;
    transition: .3s;

    &:hover {
      color: $theme-color-1;
    }

    &-prev {
      left: 0;
      transform: rotate(90deg);
    }

    &-next {
      right: 0;
      transform: rotate(-90deg);
    }
  }

  &--pagination {
    display: inline-flex;
    justify-content: center;
    gap: .625rem;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 10;

    @include media-breakpoint-up(md) {
      gap: 1.25rem;
    }

    > .swiper-pagination-bullet {
      flex-basis: .25rem;
      height: .25rem;
      border-radius: 50%;
      background: $white;
      cursor: pointer;

      @include media-breakpoint-up(md) {
        flex-basis: .625rem;
        height: .625rem;
      }

      &-active {
        background: $theme-color-1;
        cursor: default;
      }
    }
  }
}