// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: local('Roboto Light'), url('/assets/fonts/roboto/roboto-light.woff2') format('woff2');
  font-display: fallback;
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: local('Roboto'), url('/assets/fonts/roboto/roboto-regular.woff2') format('woff2');
  font-display: fallback;
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: local('Roboto Medium'), url('/assets/fonts/roboto/roboto-medium.woff2') format('woff2');
  font-display: fallback;
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: local('Roboto Bold'), url('/assets/fonts/roboto/roboto-bold.woff2') format('woff2');
  font-display: fallback;
}

@font-face {
  font-family: 'Exo2';
  font-weight: 400;
  src: local('Exo 2'), url('/assets/fonts/exo2/exo2-regular.woff2') format('woff2');
  font-display: fallback;
}

@font-face {
  font-family: 'Exo2';
  font-weight: 600;
  src: local('Exo 2 Semi Bold'), url('/assets/fonts/exo2/exo2-semibold.woff2') format('woff2');
  font-display: fallback;
}

@font-face {
  font-family: 'Exo2';
  font-weight: 700;
  src: local('Exo 2 Bold'), url('/assets/fonts/exo2/exo2-bold.woff2') format('woff2');
  font-display: fallback;
}

@font-face {
  font-family: 'Exo2';
  font-weight: 900;
  src: local('Exo 2 Extra Bold'), url('/assets/fonts/exo2/exo2-extrabold.woff2') format('woff2');
  font-display: fallback;
}

/* Generated by Glyphter (http://www.glyphter.com) on  Fri Dec 31 2021*/
@font-face {
  font-family: 'carfin';
  src: url('/assets/fonts/carfin/carfin.eot');
  src: url('/assets/fonts/carfin/carfin.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/carfin/carfin.woff') format('woff'),
       url('/assets/fonts/carfin/carfin.ttf') format('truetype'),
       url('/assets/fonts/carfin/carfin.svg#carfin') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}